
import { Component, Vue } from 'vue-property-decorator'
import { initWangEdit } from '@/utils/wangEdit'
import UploadFile from '@/components/uploadFile/Index.vue'
import { Detail } from '../../types/insectPests'
import { FileInfo, TypeInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'

@Component({
  components: { UploadFile },
  filters: {
    imgListFilter (arr: Array<FileInfo>) {
      return arr.map(item => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class InsectPestsAdd extends Vue {
  private typeList: TypeInfo[] = []
  private monthList = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
  private happenTimeList = []
  private tabList = ['识别特征', '发生规律', '防治方法', '危害虫态及危害状', '参考文献']
  private tabIndex = '0'
  private info: Detail = {
    pestsName: '',
    scientificName: '',
    pestsOtherName: '',
    pestsType: '',
    orders: '',
    family: '',
    genus: '',
    happenArea: '',
    harmPlants: '',
    harmPart: '',
    medicineKind: '',
    happenTime: '',
    resourceList: [],
    recognitionFeature: '',
    happenRegular: '',
    preventionMethod: '',
    symptom: '',
    reference: ''
  }

  private rules = {
    pestsName: [{ required: true, message: '请输入虫害名称', trigger: ['blur', 'change'] }],
    scientificName: [{ required: true, message: '请输入学名', trigger: ['blur', 'change'] }],
    pestsType: [{ required: true, message: '请选择虫害类别', trigger: ['change'] }],
    resourceList: [{ required: true, message: '请选择上传虫害图片', trigger: ['change'] }],
    recognitionFeature: [{ required: true, message: '请输入识别特征' }],
    happenRegular: [{ required: true, message: '请输入发生规律' }],
    preventionMethod: [{ required: true, message: '请输入防治方法' }]
  }

  private editorRecognitionFeature: any = null
  private editorHappenRegular: any = null
  private editorPreventionMethod: any = null
  private editorSymptom: any = null
  private editorReference: any = null
  private submitShow = false
  get pestsId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getTypeList()
    this.init()
    if (this.pestsId) {
      this.getDetail()
    }
  }

  destroyed () {
    // 销毁编辑器
    this.editorRecognitionFeature.destroy()
    this.editorHappenRegular.destroy()
    this.editorPreventionMethod.destroy()
    this.editorSymptom.destroy()
    this.editorReference.destroy()
    this.editorRecognitionFeature = null
    this.editorHappenRegular = null
    this.editorPreventionMethod = null
    this.editorSymptom = null
    this.editorReference = null
  }

  getTypeList () {
    this.$axios.get(this.$apis.common.thinktankTypeList, {
      dicType: 'pestsType'
    }).then((res) => {
      this.typeList = res.list || []
    })
  }

  // 富文本初始化
  init () {
    this.$nextTick(() => {
      this.editorRecognitionFeature = initWangEdit('#recognitionFeature', { placeholder: '请输入识别特征' })
      this.editorRecognitionFeature.create()
      this.editorHappenRegular = initWangEdit('#happenRegular', { placeholder: '请输入发生规律' })
      this.editorHappenRegular.create()
      this.editorPreventionMethod = initWangEdit('#preventionMethod', { placeholder: '请输入防治方法' })
      this.editorPreventionMethod.create()
      this.editorSymptom = initWangEdit('#symptom', { placeholder: '请输入危害虫态及危害状' })
      this.editorSymptom.create()
      this.editorReference = initWangEdit('#reference', { placeholder: '请输入参考文献' })
      this.editorReference.create()
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.insectPests.selectPestsDetail, {
      pestsId: this.pestsId
    }).then(res => {
      this.info = res
      this.happenTimeList = res.happenTimeList || []
      this.editorRecognitionFeature.txt.html(res.recognitionFeature || '')
      this.editorHappenRegular.txt.html(res.happenRegular || '')
      this.editorPreventionMethod.txt.html(res.preventionMethod || '')
      this.editorSymptom.txt.html(res.symptom || '')
      this.editorReference.txt.html(res.reference || '')
    })
  }

  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  onSubmit () {
    // 必填项赋值
    // 获取富文本内容
    this.info.recognitionFeature = this.editorRecognitionFeature.txt.html()
    this.info.happenRegular = this.editorHappenRegular.txt.html()
    this.info.preventionMethod = this.editorPreventionMethod.txt.html()
    this.tabIndex = this.info.recognitionFeature === '' ? '0' : this.info.happenRegular === '' ? '1' : this.info.symptom === '' ? '2' : this.tabIndex
    ;(this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.pestsId ? this.$apis.insectPests.updatePests : this.$apis.insectPests.insertPestsAdd
        this.info.happenTime = this.happenTimeList.length > 0 ? this.happenTimeList.join(',') : ''
        this.info.symptom = this.editorSymptom.txt.html()
        this.info.reference = this.editorReference.txt.html()

        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.push('/insectPests')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
